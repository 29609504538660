// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { lock, unlock } from 'tua-body-scroll-lock';
import SmoothScroll from 'smooth-scroll';
export default class NavEvent {
  constructor() {
    this.init();
  }
  init() {
    const toggles = document.querySelector('.js-NavToggle');
    const Nav = document.querySelector('.js-NavContent');
    const headerWrap = document.querySelector('.p_headerWrap');
    const mobileNavs = document.querySelectorAll('.js-mobileNav');
    const bd = document.querySelector('.p_headerBodyBg');
    for (let i = 0; i < mobileNavs.length; i++) {
      mobileNavs[i].addEventListener('click', (e) => {
        setTimeout(() => {
          Nav.classList.remove('Act');
          toggles.classList.remove('Act');
        }, 320);
        unlock(Nav, { overflowType: 'clip' });
      });
    }

    bd.addEventListener('click', (e) => {
      unlock(Nav, { overflowType: 'clip' });
      Nav.classList.remove('Act');
      toggles.classList.remove('Act');
    });

    toggles.addEventListener('click', (e) => {
      if (Nav.classList.contains('Act')) {
        unlock(Nav, { overflowType: 'clip' });
      } else {
        lock(Nav, { overflowType: 'clip' });
      }
      Nav.classList.toggle('Act');
      toggles.classList.toggle('Act');
    });

    window.addEventListener('scroll', () => {
      let posY = window.scrollY;
      if (posY > 200) {
        headerWrap.classList.add('Pull');
      } else {
        headerWrap.classList.remove('Pull');
      }
    });

    const headerObj = document.querySelector('.p_headerWrap');
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 300,
      header: '[data-scroll-header]',
      topOnEmptyHash: true,
      speedAsDuration: true,
      offset: 10,
      // easing: 'easeInOutCubic',
      updateURL: false,
    });
  }
}
//
