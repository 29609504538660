'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';

import NavEvent from './components/NavEvent';
import InViewEvent from './components/inViewEvent';

import SimpleParallax from 'simple-parallax-js/vanilla';

document.addEventListener('DOMContentLoaded', () => {
  // console.log('Hello BORDER');
  const navEvent = new NavEvent();
  const inViewEvent = new InViewEvent();

  const faqs = document.querySelectorAll('.c_faqCard');
  for (let i = 0; i < faqs.length; i++) {
    faqs[i].addEventListener('click', (e) => {
      let target = e.currentTarget;
      target.classList.toggle('Act');
    });
  }

  const images = document.querySelectorAll('.p_CircleObj');
  const instance = new SimpleParallax(images, {
    maxTransition: 99,
    overflow: true,
    scale: 1.2,
    orientation: 'down',
  });

  const KVImage = document.querySelector('.p_KVImage');
  const KV = new SimpleParallax(KVImage, {
    maxTransition: 99,
    overflow: true,
    scale: 1.2,
    orientation: 'down',
  });

  const featureImage = document.querySelector('.p_featureImage');
  const feature = new SimpleParallax(featureImage, {
    maxTransition: 99,
    // overflow: true,
    scale: 1.1,
  });

  const contactImage = document.querySelector('.p_contactImage');
  const contact = new SimpleParallax(contactImage, {
    maxTransition: 99,
    overflow: true,
    scale: 1.1,
    orientation: 'down',
  });
});
